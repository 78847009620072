









































































import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import { UpdateOperationTemplateInput } from "../../../types/gqlGeneratedPrivate";
import AppLayout from "../../../components/commons/layouts/AppLayout.vue";
import Button from "../../../components/commons/inputsAndControls/Button.vue";
import TextField from "../../../components/commons/inputsAndControls/TextField.vue";

@Component({
  components: {
    AppLayout,
    Button,
    TextField,
    ValidationObserver,
  },
})
export default class SettingsForm extends Vue {
  @Prop({ type: Object, required: true })
  value!: UpdateOperationTemplateInput;

  input(name: string, value: string | number) {
    this.$emit("input", { ...this.value, [name]: value });
  }

  submitForm() {
    this.$emit("submit");
  }

  toNumber(val: string): number | string {
    const n = parseFloat(val);
    return isNaN(n) ? val : n;
  }
}
