






















import AppLayout from "../components/commons/layouts/AppLayout.vue";
import { Component, Vue } from "vue-property-decorator";
import {
  MutationUpdateControlSettingsArgs,
  MutationUpdateDriverSettingsArgs,
  OperationTemplate,
  SettingsQuery,
  SettingsQueryVariables,
  UpdateOperationTemplateInput,
} from "../types/gqlGeneratedPrivate";
import { alerts, apiClient } from "../utils";
import { api } from "../api";
import SettingsForm from "../components/app/settings/SettingsForm.vue";

@Component({
  components: {
    SettingsForm,
    AppLayout,
  },
})
export default class SettingsPage extends Vue {
  formDriver: UpdateOperationTemplateInput = {
    name: "",
    value: 0,
    valueTwo: 0,
    minimumTime: 0,
    minimumTimeTwo: 0,
    comfortTime: 0,
    comfortTimeTwo: 0,
  };

  formControl: UpdateOperationTemplateInput = {
    name: "",
    value: 0,
    valueTwo: 0,
    minimumTime: 0,
    minimumTimeTwo: 0,
    comfortTime: 0,
    comfortTimeTwo: 0,
  };

  async created() {
    const { data } = await apiClient.callGraphqlPrivate<
      SettingsQuery,
      SettingsQueryVariables
    >({
      ...api.settings.settings,
      variables: {},
    });

    if (data?.settings) {
      this.formControl = data.settings.control;
    }
    if (data?.settings) {
      this.formDriver = data.settings.driver;
    }
  }

  async submitFormDriver() {
    const result = await apiClient.callGraphqlPrivate<
      OperationTemplate,
      MutationUpdateDriverSettingsArgs
    >({
      ...api.settings.updateDriver,
      variables: { input: this.formDriver },
    });

    if (result.data) {
      alerts.addSuccessAlert("UPDATE_DRIVER_SETTINGS", "Uloženo");
    }
  }

  async submitFormControl() {
    const result = await apiClient.callGraphqlPrivate<
      OperationTemplate,
      MutationUpdateControlSettingsArgs
    >({
      ...api.settings.updateControl,
      variables: { input: this.formControl },
    });

    if (result.data) {
      alerts.addSuccessAlert("UPDATE_CONTROL_SETTINGS", "Uloženo");
    }
  }
}
